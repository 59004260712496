import 'slick-carousel';
import '../../themes/home.css'

jQuery(async function () {
	$('.home .slick-carousel').slick({
		dots: true,
		arrows: false,
		infinite: false,
		autoplay: false,
		speed: 300,
		slidesToShow: 4,
		touchThreshold: 1000,
		slidesToScroll: 4,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}/*,
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			*/
		]
	});

	$(".prev-btn").click(function () {
		$(this).closest('div.products').find("div.slick-carousel").slick("slickPrev");
	});

	$(".next-btn").click(function () {
		$(this).closest('div.products').find("div.slick-carousel").slick("slickNext");
	});
	$(".prev-btn").addClass("slick-disabled");
	$(this).closest('div.products').find("div.slick-carousel").on("afterChange", function () {
		if ($(".slick-prev").hasClass("slick-disabled")) {
			$(".prev-btn").addClass("slick-disabled");
		} else {
			$(".prev-btn").removeClass("slick-disabled");
		}
		if ($(".slick-next").hasClass("slick-disabled")) {
			$(".next-btn").addClass("slick-disabled");
		} else {
			$(".next-btn").removeClass("slick-disabled");
		}
	});
});